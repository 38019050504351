<template>
  <div class="vg_wrapper">
    <el-tabs v-model="activeName" type="border-card">
      <el-tab-pane label="单据信息" name="first" :key="'first'">
        <childTab1 />
      </el-tab-pane>
      <!-- <el-tab-pane label="相关文档" name="second" :key="'second'">
          <childTab2/>
        </el-tab-pane> -->
    </el-tabs>
  </div>
</template>

<script>
import CpttAddMain from '@/views/SystemManagement/CpttManage/TabChild/CpttAddMain';
// import CpttAddAbout from "@/views/SystemManagement/CpttManage/TabChild/CpttAddAbout";
export default {
  name: 'CpttAdd',
  components: {
    childTab1: CpttAddMain
    // childTab2: CpttAddAbout
  },
  data() {
    return {
      activeName: 'first'
    };
  },
  created() {},
  methods: {}
};
</script>

<style scoped lang="scss"></style>
